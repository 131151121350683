
import { defineComponent, reactive, computed } from "vue";
import moment from 'moment';
import {
  useQuery,
} from "@/core/helpers/common-helper";
import useChannel from "@/core/services/compositions/enum/useChannel";
import useInstitution from "@/core/services/compositions/enum/useInstitution";

interface Filter {
  channel: string;
  institution: string;
  status: string;
  date: Array<string>;
}

export default defineComponent({
  name: "filter-invocation",
  components: {},
  setup(props, context) {
    const queryParam = reactive({
      search: ''
    });

    const fetchParams = computed(() => ({
      name: queryParam.search,
      order_by: 'created_at',
      order: 'DESC',
      ts: moment().format('YYYY-MM-DDTHH:mm:ss.SSS'),
    }));

    const { channel } = useChannel();
    const { institution, isValidating: isValidatingInstitution } = useInstitution(() => fetchParams.value);
    const { query } = useQuery();

    const data = reactive<Filter>({
      channel: query.value.channel || '',
      institution: query.value.institution || '',
      status: query.value.status || '',
      date: query.value.date || [moment().format('YYYY-MM-DD'), moment().format('YYYY-MM-DD')],
    });

    const filter = () => {
      context.emit('filtered', data);
    };

    const reset = () => {
      data.channel = '';
      data.institution = '';
      data.status = '';
      data.date = [moment().format('YYYY-MM-DD'), moment().format('YYYY-MM-DD')];
    };

    const searchInstitution = (val) => {
      queryParam.search = val;
    }

    return {
      data,
      filter,
      reset,
      channel,
      isValidatingInstitution,
      institution,
      searchInstitution
    };
  },
});
