
import { reactive, defineComponent, computed, ref, onMounted, watch } from "vue";
import { useI18n } from "vue-i18n";
import {
  showErrorPopup,
  showSuccessPopup,
  useQuery,
  useMutateQuery,
} from "@/core/helpers/common-helper";
import { setNewPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import TableSkeleton from "@/components/skeleton/Table.vue";
import Pagination from "@/components/Pagination.vue";
import useReportChannelList from "@/core/services/compositions/report/useReportChannelList";
import moment from 'moment';
import { ElNotification } from 'element-plus'
import { useRoute, useRouter } from "vue-router";
import FilterReport from "./filter-report.vue";
import { MenuComponent } from "@/assets/ts/components";
import { numberFormat } from "@/core/helpers/currency-helper";
import { checkPermission, checkRole } from '@/router/authentication'
import ApiService from "@/core/services/ApiService";

export default defineComponent({
  components: {
    Pagination,
    TableSkeleton,
    FilterReport
  },
  setup() {
    const router = useRouter();
    const route = useRoute();
    const isGenerate = ref<boolean>(false);
    const dialogVisible = ref(false);

    // init
    const { t } = useI18n();

    // breadcrumb
    setNewPageBreadcrumbs(t("reports.channel.breadcrumb"), [
      { title: t("reports.channel.title") }
    ]);

    const paginationRef = ref(null);
    const { query } = useQuery();

    const transaction = reactive({
      date: moment().format('YYYY-MM-DD'),
    });

    const form = reactive({
      channel: '',
      institution: '',
      status: '',
      date: [moment().format('YYYY-MM-DD'), moment().format('YYYY-MM-DD')],
      order_by: 'created_at',
      order: 'DESC',
      ts: moment().format('YYYY-MM-DDTHH:mm:ss.SSS'),
    })

    const queryChannel = computed(() => query.value.channel || form.channel);

    const fetchParams = computed(() => ({
      page: query.value.page || 1,
      limit: query.value.limit || 20,
      channel: query.value.channel || form.channel,
      institution: query.value.institution || form.institution,
      status: query.value.status || form.status,
      date: query.value.date || form.date,
      order_by: 'created_at',
      order: 'DESC',
      ts: moment().format('YYYY-MM-DDTHH:mm:ss.SSS'),
    }));

    const { data, isValidating, mutate: refreshList, error } = useReportChannelList(() => fetchParams.value);

    watch(error, (error) => {
      if (error.response.status == 403) {
        router.push({ name: "403" });

        return;
      }
      ElNotification({
        title: error.response.data.rc,
        message: error.response.data.message,
        type: 'error',
      })
    });

    watch(query, (query) => {
      if (Object.keys(query).length === 0) {
        Object.assign(form, {
          channel: '',
          institution: '',
          status: '',
          date: [moment().format('YYYY-MM-DD'), moment().format('YYYY-MM-DD')],
          order_by: 'created_at',
          order: 'DESC',
          ts: moment().format('YYYY-MM-DDTHH:mm:ss.SSS'),
        });
      }
    });

    const { mutateQuery } = useMutateQuery();
    const handleChangePage = (nextPage: number) => {
      mutateQuery({
        page: nextPage,
      });
    };

    const generate = () => {
      dialogVisible.value = false;
      isGenerate.value = true;

      const payload = {
        transaction_date: transaction.date
      }

      ApiService.setHeader();
      ApiService.post(
        `app/reports/channel/summary`,
        payload as any
      )
        .then(() => {
          showSuccessPopup({
            title: 'Generate Summary Transaction',
            text: 'Success'
          });

          refreshList();

        })
        .catch(({ response }) => {
          if (Object.prototype.hasOwnProperty.call(response.data, "data")) {
            if (Object.prototype.hasOwnProperty.call(response.data.data, "message")) {
              showErrorPopup({
                title: response.data.rc,
                text: response.data.data.message[0],
                message: response.data.data.message[0],
              })

              return;
            }

            if (response.data.data.length > 0) {
              for (const key in response.data.data) {
                showErrorPopup({
                  title: response.data.rc,
                  text: response.data.data[key],
                  message: response.data.data[key],
                })
              }
            } else {
              showErrorPopup({
                title: response.data.rc,
                text: response.data.message,
                message: response.data.message,
              })
            }
          } else {
            showErrorPopup({
              title: response.data.rc,
              text: response.data.message,
              message: response.data.message,
            })
          }
        })
        .finally(() => {
          isGenerate.value = false;
        });
    };

    const filtered = (filters) => {
      if (screen.width <= 760) {
        MenuComponent.hideDropdowns(undefined);
      }
      Object.assign(form, filters);

      mutateQuery({
        page: 1,
        channel: form.channel,
        date: form.date,
        order_by: 'created_at',
        order: 'DESC',
        ts: moment().format('YYYY-MM-DDTHH:mm:ss.SSS'),
      });
    };

    const showDialog = () => {
      if (transaction.date == null || transaction.date == '') {
        showErrorPopup({
          title: 'Validation',
          text: 'Date cannot empty',
          message: 'Date cannot empty',
        });

        return;
      }
      dialogVisible.value = true;
    }

    onMounted(() => {
      MenuComponent.reinitialization();
    });

    return {
      data,
      isValidating,
      handleChangePage,
      filtered,
      numberFormat,
      checkPermission,
      moment,
      queryChannel,
      generate,
      isGenerate,
      dialogVisible,
      showDialog,
      transaction,
      checkRole
    };
  }
});
